export interface PageInfo {
    readonly url: string,
    readonly name: string,
    readonly icon: string,
}

export const createPageInfo = (name: string, url: string, icon: string): PageInfo => ({ name: name, url: url, icon: icon })

export const mainSitePages: PageInfo[] = [
    createPageInfo("Home", "/", "house"),
    createPageInfo("Chi siamo", "/details", "circle-info"),
    createPageInfo("Eventi", "/events", "calendar"),
    createPageInfo("Galleria", "/gallery", "image"),
    createPageInfo("Classifica", "/scores", "ranking-star"),
    createPageInfo("Consiglio direttivo", "/group", "users"),
    createPageInfo("Area riservata", "/admin/", "user"),
]